import React from 'react';

const Siderval = () => {
    return (
        <div>
            Platadorma de gestion de Inventario y Línea Productiva
        </div>
    );
};

export default Siderval;